import styled from "styled-components";

const Container = styled.div`
  height: 30px;
  background-color: green;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
`;

const Announcement = () => {
  return (
    <Container>
      We Deliver Products to Every State in Nigeria and Internationally... call:
      +234 818 183 7137
    </Container>
  );
};

export default Announcement;
