import React from "react";
import styled from "styled-components";
import { testimonials } from "../data";

const TestimonialsWrapper = styled.div`
  display: flex;
  background-color: green;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 800px;
`;

const TestimonialContainer = styled.div`
  flex: 0 0 30%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
`;

const TestimonialText = styled.p`
  font-size: 16px;
`;

const TestimonialName = styled.h3`
  font-size: 18px;
  margin-top: 10px;
`;

const TestimonialHeader = styled.h2`
  font-size: 25px;
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
  color: #fff;
  font-weiht: bold;
`;

const TestimonialCompany = styled.p`
  font-size: 14px;
  font-style: italic;
  color: #888;
`;

const Testimonial = () => {
  return (
    <TestimonialsWrapper>
      <TestimonialHeader>What People Say About Us</TestimonialHeader>
      {testimonials.map((testimonial) => (
        <TestimonialContainer key={testimonial.id}>
          <TestimonialText>{testimonial.text}</TestimonialText>
          <TestimonialName>{testimonial.name}</TestimonialName>
          {/* <TestimonialCompany>{testimonial.company}</TestimonialCompany> */}
        </TestimonialContainer>
      ))}
    </TestimonialsWrapper>
  );
};
export default Testimonial;
