import React from "react";
import styled from "styled-components";
import { workings } from "../data";

const TestimonialsWrapper = styled.div`
  display: flex;
  // flex-direction: column;
  // background-color: green;
  justify-content: space-between;
  margin: 30px auto;
  max-width: 1000px;
`;

const TestimonialContainer = styled.div`
  display: flex
  flex-direction: row;
  flex: 0 0 30%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-right: 5px;
`;

const TestimonialText = styled.p`
  font-size: 16px;
`;

const TestimonialName = styled.h3`
  font-size: 18px;
  margin-top: 10px;
`;

const TestimonialHeader = styled.h2`
  font-size: 25px;
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
  color: green;
  font-weiht: bold;
  text-transform: uppercase;
`;

const TestimonialCompany = styled.p`
  font-size: 14px;
  font-style: italic;
  color: #888;
`;

const About = () => {
  return (
    <>
      <TestimonialHeader>How Jigsimur Works</TestimonialHeader>
      <TestimonialsWrapper>
        {workings.map((testimonial) => (
          <TestimonialContainer key={testimonial.id}>
            <TestimonialText>{testimonial.text}</TestimonialText>
            <TestimonialName>{testimonial.name}</TestimonialName>
            {/* <TestimonialCompany>{testimonial.company}</TestimonialCompany> */}
          </TestimonialContainer>
        ))}
      </TestimonialsWrapper>
    </>
  );
};
export default About;
