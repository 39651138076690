import styled from "styled-components";
import { mobile } from "../responsive";
import { useState } from "react";
import axios from "axios";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("https://fecotony.sirv.com/olo/treatment%20pack%20jigsimur.png") center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 40%;
  padding: 20px;
  background-color: white;
  ${mobile({ width: "75%" })}
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 20px 10px 0px 0px;
  padding: 10px;
`;

const Agreement = styled.span`
  font-size: 12px;
  margin: 20px 0px;
`;

const ContactForm = () => {
  const [formData, setFormData] = useState({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    Name_on_Bank_Account: "",
    Bank_Name: "",
    Account_Number: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  console.log(formData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData);

    try {
      await axios.post("http://localhost:5000/api/send-email", formData);
      console.log("Email sent successfully");

      setFormData({
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        Name_on_Bank_Account: "",
        Bank_Name: "",
        Account_Number: "",
      });
    } catch (err) {
      console.log(err, "error sending email");
    }
  };

  return (
    <div>
      <Container>
        <Wrapper>
          <Title>REGISTER TO BECOME A DISTRIBUTOR</Title>
          <Form onSubmit={handleSubmit}>
            <Input
              name="username"
              value={formData.username}
              placeholder="username"
              onChange={handleChange}
              required
            />
            <Input
              name="firstName"
              value={formData.firstName}
              placeholder="firstName"
              onChange={handleChange}
              required
            />
            <Input
              name="lastName"
              value={formData.lastName}
              placeholder="user_name"
              onChange={handleChange}
              required
            />
            <Input
              type="email"
              name="email"
              value={formData.email}
              placeholder="user_email"
              onChange={handleChange}
              required
            />
            <Input
              type="nummber"
              name="phone"
              value={formData.phone}
              placeholder="user_phone_number"
              onChange={handleChange}
              required
            />
            <Input
              type="text"
              name="Name_on_Bank_Account"
              value={formData.Name_on_Bank_Account}
              placeholder="name_on_bank_account"
              onChange={handleChange}
              required
            />
            <Input
              type="text"
              name="Bank_Name"
              value={formData.Bank_Name}
              placeholder="Bank Name"
              onChange={handleChange}
              required
            />
            <Input
              name="Account_Number"
              type="number"
              value={formData.Account_Number}
              placeholder="Account Number"
              onChange={handleChange}
              required
            />

            <Agreement>
              By creating an account, I consent to the processing of my personal
              data in accordance with the <b>PRIVACY POLICY</b>
            </Agreement>
            <button
              type="submit"
              style={{
                width: "40%",
                border: "none",
                padding: "10px 20px",
                backgroundColor: "green",
                color: "white",
                cursor: "pointer",
              }}
            >
              CREATE
            </button>
          </Form>
        </Wrapper>
      </Container>
    </div>
  );
};

export default ContactForm;
