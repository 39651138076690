export const sliderItems = [
    {
      "id": 1,
      "img": "https://fecotony.sirv.com/olo/treatment%20pack%20jigsimur.png",
      "title": "TREATMENT PACK",
      "price": "NGN 83,000",
      "priced": 83000,
      "desc": "Jigsimur helps remove all those toxins in your body that have been causing problems in your body, there are so many substances inside our body that are bad for our Health. Jigsimur removes these substances through a process called DETOXIFICATION.",
      "bg": "f5fafd",
    },
    {
      "id": 2,
      "img": "https://fecotony.sirv.com/olo/jigsimur%20small%20bottle.png",
      "title": "SMALL BOTTLE",
      "price": "NGN 8,000",
      "priced": 8000,
      "desc": "Jigsimur is a very POWERFUL IMMUNE BOOSTER which means it is capable of fighting and curing any form of infection and disease in the body.",
      "bg": "fcf1ed",
    },
    {
      "id": 3,
      "img": "https://fecotony.sirv.com/olo/one%20bottle%20Jigsimur.png",
      "title": "BIG BOTTLE",
      "price": "NGN 18,000",
      "priced": 18000,
      "desc": "Jigsimur helps the Body in the Healing Process. The human body is known to heal itself but Jigsimur enables the Healing process to be faster..",
      "bg": "fbf0f4",
    },
];

export   const workings = [
    {
      id: 1,
      name: "Detoxification",
      text: "Jigsimur contains a blend of natural ingredients, including herbs and antioxidants, which work synergistically to support the body's detoxification processes. These ingredients help to eliminate toxins and waste products from the body, particularly from the digestive system and liver. By promoting detoxification, Jigsimur helps to cleanse the body at a cellular level, allowing organs to function optimally and promoting overall health and well-being.",
      company: "",
    },
    {
      id: 2,
      name: "Nutrient Absorption",
      text: "One of the key benefits of Jigsimur is its ability to enhance nutrient absorption. The herbs and plant compounds found in Jigsimur help to improve digestion and assimilation of nutrients from the foods we eat. This is essential for ensuring that the body receives the essential vitamins, minerals, and other nutrients it needs to thrive. By optimizing nutrient absorption, Jigsimur supports various bodily functions, including energy production, immune function, and tissue repair.",
      company: "",
    },
    {
      id: 3,
      name: "Balancing the Body",
      text: "Jigsimur works to restore balance within the body by regulating various physiological processes. It helps to support hormonal balance, immune function, and metabolic processes, which are essential for overall health and well-being. Additionally, Jigsimur has anti-inflammatory properties that help to reduce inflammation and promote healing within the body. By promoting balance and reducing inflammation, Jigsimur supports optimal health and vitality.",
      company: "",
    }
];

export   const testimonials = [
    {
      id: 1,
      name: "Chinasa Emeka",
      text: "Jigsimur has been a game-changer for me! I've struggled with digestive issues for years, but since incorporating Jigsimur into my daily routine, I've experienced significant relief. Not only do I feel better physically, but I've also noticed an improvement in my skin complexion and overall vitality. Plus, the taste is surprisingly pleasant. I'm so grateful for this herbal remedy",
      company: "ABC Inc.",
    },
    {
      id: 2,
      name: "Bisola Tunde",
      text: "As someone who prefers natural remedies, I was thrilled to discover Jigsimur. This product has exceeded my expectations in every way. Not only does it provide a gentle detox for my body, but it also boosts my energy levels without any jittery side effects. I've also noticed improvements in my immune system and general well-being since incorporating Jigsimur into my health regimen. It's become a staple in my daily routine, and I couldn't be happier with the results!.",
      company: "XYZ Corp.",
    },
    {
      id: 3,
      name: "Emmanuel Johnson",
      text: "I can't say enough good things about Jigsimur! This herbal elixir has truly transformed my life. After struggling with chronic fatigue and low energy for years, I was skeptical about trying yet another supplement. However, Jigsimur proved to be a game-changer. Within just a few days of taking it, I noticed a remarkable difference in my energy levels and overall vitality. I feel more focused, rejuvenated, and ready to tackle whatever life throws my way. Jigsimur is now a non-negotiable part of my wellness routine, and I recommend it to everyone looking to revitalize their health naturally",
      company: "123 Co.",
    }
];

export const categories = [
    {
      id: 1,
      img: "https://images.pexels.com/photos/5886041/pexels-photo-5886041.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      title: "SHIRT STYLE!",
    },
    {
      id: 2,
      img: "https://images.pexels.com/photos/2983464/pexels-photo-2983464.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      title: "LOUNGEWEAR LOVE",
    },
    {
      id: 3,
      img: "https://images.pexels.com/photos/5480696/pexels-photo-5480696.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
      title: "LIGHT JACKETS",
    },
];

export const popularProducts = [
    {
      id:1,
      img:"https://fecotony.sirv.com/olo/treatment%20pack%20jigsimur.png",
    },
    {
      id:2,
      img:"https://fecotony.sirv.com/olo/one%20bottle%20Jigsimur.png",
    },
    {
      id:3,
      img:"https://fecotony.sirv.com/olo/jigsimur%20small%20bottle.png",
    }
   
]

export const data = [
 
  {
    "userId": 1,
    "id": 3,
    "title": "Starter Registration",
    "body": "STARTER Registration is  N83,500 You get 5 BIG bottle of 750ml."
  },
  {
    "userId": 1,
    "id": 3,
    "title": "Star Registration",
    "body": "Registration is N256,000 You get one carton and 3 bottles of Jigsimur (15 big bottles, each 750ml)."
  },
  {
    "userId": 1,
    "id": 4,
    "title": "Entrepreneur Registration",
    "body": "Registration is N595,000 You get 2 cartons and 11 big bottles (35 big bottles, each 750ml each)."
  },
  {
    "userId": 1,
    "id": 5,
    "title": "Super Entrepreneur Registration",
    "body": "Registration is N1,275,000 You get 6 cartons and 3 big bottles (75 big bottles, each 750ml)"
  },

  {
    "userId": 1,
    "id": 8,
    "title": "Franchise Registration",
    "body": "Registration = N2,635,000 You get 12 cartons and 11 big bottles(155 big bottles, each 750ml)"
  }
]
