import React, { useState } from "react";
import { data } from "../data";

import styled from "styled-components";

const AccordionWrapper = styled.div`
  width: 100%;
`;

const AccordianHeader = styled.div`
  text-align: center;
  color: green;
`;

const Section = styled.div`
  border-bottom: 1px solid #ccc;
`;

const Header = styled.div`
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
  background-color: ${({ isOpen }) => (isOpen ? "#f0f0f0" : "transparent")};
`;

const Content = styled.div`
  padding: 10px 20px;
  background-color: green;
  font-weight: bold;
  color: #fff;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

const Accordion = ({ sections }) => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    setOpenSection((prevOpenSection) =>
      prevOpenSection === index ? null : index
    );
  };

  return (
    <AccordionWrapper>
      <AccordianHeader>
        <h1>Becoming an Affiliate Distributor of Jigsimur</h1>
        <h2>There are 5 Entry Levels</h2>
      </AccordianHeader>
      {data.map((section, index) => (
        <Section key={index}>
          <Header
            isOpen={openSection === index}
            onClick={() => toggleSection(index)}
          >
            {section.title}
          </Header>
          <Content isOpen={openSection === index}>{section.body}</Content>
        </Section>
      ))}
    </AccordionWrapper>
  );
};

export default Accordion;
