import React from "react";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Products from "../components/Products";
import Slider from "../components/Slider";
import ContactForm from "../components/ContactForm";
import Accordian from "../components/Accordian";
import Testimonial from "../components/Testimonial";
import About from "../components/About";

const Home = () => {
  return (
    <div>
      <Announcement />
      <Navbar />
      <Slider />
      <About />
      <Testimonial />
      <Products />
      <ContactForm />
      <Accordian />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Home;
